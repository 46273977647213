exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-audio-js": () => import("./../../../src/pages/audio.js" /* webpackChunkName: "component---src-pages-audio-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-open-question-js": () => import("./../../../src/pages/open-question.js" /* webpackChunkName: "component---src-pages-open-question-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-email-js": () => import("./../../../src/templates/email.js" /* webpackChunkName: "component---src-templates-email-js" */),
  "component---src-templates-scenario-js": () => import("./../../../src/templates/scenario.js" /* webpackChunkName: "component---src-templates-scenario-js" */)
}

